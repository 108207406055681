// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_EMICRANIA = "/emicrania";
const ROOTS_DEPRESSIONE = "/sintomidepressivi";

// ----------------------------------------------------------------------

export const PATH_PAGE = {
  privacy: "/privacy",
};

export const PATH_EMICRANIA = {
  root: ROOTS_EMICRANIA,
  survey: path(ROOTS_EMICRANIA, "/survey"),
};

export const PATH_DEPRESSIONE = {
  root: ROOTS_DEPRESSIONE,
  survey: path(ROOTS_DEPRESSIONE, "/survey"),
};
