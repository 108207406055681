import React from "react";
// material
import { Grid, Typography } from "@mui/material";

// ----------------------------------------------------------------------

export default function MessageRight({ canProceed }) {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={10} md={9}>
        <img
          alt="depressione"
          src={canProceed ? `/images/icon003.png` : `/images/icon004.png`}
          height={148}
        />
      </Grid>
      <Grid item xs={10} md={9}>
        <Typography variant="h5" color="secondary" sx={{ my: 2 }}>
          QUESTIONARIO SINTOMI DELL’UMORE
        </Typography>
      </Grid>
      <Grid item xs={10} md={9}>
        <Typography variant="body2">
          Se vuoi avere un colloquio gratuito con specialisti per parlare dei
          tuoi sintomi dell’umore,{" "}
          <strong>
            compila il questionario (da scaricare e portare con te) e prenota
            subito dopo il tuo colloquio per l’11 giugno 2023
          </strong>{" "}
          presso la <strong>Fondazione Riccardo Catella</strong>, all’interno
          del parco BAM - Biblioteca degli Alberi, in Via Gaetano de Castillia,
          28 - Milano.
          <br />
          <br />
          Affrettati perchè i posti sono limitati!
          <br />
          <br />
          <strong>
            Questo questionario, basato su una breve scala specifica per la
            Medicina Generale chiamata Patient Health Questionnaire-9 (PHQ-9),
            non ha valore diagnostico ed è funzionale all’organizzazione delle
            prenotazioni e a rendere più efficace il tuo colloquio.
          </strong>
          <br />
        </Typography>
      </Grid>
    </Grid>
  );
}
