// material
import { Grid, Typography, Link, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Privacy() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          backgroundImage: isMobile ? `` : `url(/images/background.png)`,
          backgroundPosition: "initial",
          backgroundRepeat: "noRepeat",
          backgroundSize: "cover",
          backgroundColor: isMobile ? "#E8F1F3" : "transparent",
          py: 10,
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={10} md={8}>
            <Typography variant="body2">
              Informativa per il trattamento dei dati personali <br />
              In relazione all’evento “Cambia la tua relazione con l’emicrania”
              organizzato da Lundbeck Italia S.p.A. e che si terrà domenica 11
              giugno 2023, presso BAM - Biblioteca degli Alberi, Milano (di
              seguito, l’“Evento”), Lundbeck Italia S.p.A. (di seguito,
              "Lundbeck" o "noi"), in qualità di titolare del trattamento dati,
              tratterà i Suoi dati personali come descritto in ulteriori
              dettagli in questa informativa sulla privacy ed in base alla
              legislazione applicabile, a partire dal Regolamento UE 2016/679
              (di seguito, “GDPR”) e dal d.lgs. 196/2003, come modificato dal
              d.lgs. 101/2018 (di seguito, “Codice della privacy”).
              <br />
              <br />
              1. Categorie di dati personali trattati <br />
              Raccogliamo direttamente da Lei e trattiamo le seguenti categorie
              di dati personali:
              <br />
              - Dati anagrafici
              <br />
              - Sesso
              <br />
              - Dati di contatto <br />- Dati relativi alla salute da Lei
              forniti volontariamente attraverso la compilazione del
              questionario disponibile sul sito web{" "}
              <Link
                href="https://www.cambialatuarelazioneconlemicrania.it/"
                target="_blank"
                variant="body2"
              >
                www.cambialatuarelazioneconlemicrania.it
              </Link>
              <br />
              <br />
              2. Finalità e base giuridica del trattamento
              <br />
              Trattiamo i Suoi dati personali per i seguenti scopi:
              <br />
              - consentirLe di accedere alla prenotazione di un colloquio
              gratuito con uno specialista presente all’Evento e gestire la Sua
              prenotazione.
              <br />
              <br />
              La base giuridica per la raccolta e il trattamento dei Suoi dati
              personali di cui al precedente punto 1 è:
              <br />• con riferimento alle categorie di dati personali comuni
              (dati anagrafici, sesso, dati di contatto), la necessità di
              fornirLe il servizio richiesto, cfr. art. 6, co. 1, lett. b),
              GDPR; - con riferimento alle categorie di dati personali
              particolari (dati relativi al Suo stato di salute), il Suo
              consenso, manifestato con azione positiva attraverso la
              compilazione del questionario disponibile sul sito web{" "}
              <Link
                href="https://www.cambialatuarelazioneconlemicrania.it/"
                target="_blank"
                variant="body2"
              >
                www.cambialatuarelazioneconlemicrania.it
              </Link>{" "}
              cfr. art. 9, co. 2, lett. a), GDPR.
              <br />
              <br />
              3. Comunicazione dei Suoi dati personali a terzi
              <br />I Suoi dati personali verranno trattati da soggetti terzi
              (quali, ad esempio, l’agenzia che gestisce il sito web{" "}
              <Link
                href="https://www.cambialatuarelazioneconlemicrania.it/"
                target="_blank"
                variant="body2"
              >
                www.cambialatuarelazioneconlemicrania.it
              </Link>
              ), che tratteranno i Suoi dati personali per le finalità di cui
              alla presente informativa, per conto e secondo le indicazioni di
              Lundbeck, in qualità di responsabili del trattamento.
              <br />
              <br />
              4. Periodo di conservazione dei Suoi dati personali <br />
              Conserviamo i suoi dati personali per il tempo necessario a
              gestire la Sua prenotazione di un colloquio gratuito con uno
              specialista presente all’Evento e, in ogni caso, provvederemo alla
              cancellazione degli stessi entro il giorno successivo allo
              svolgimento dell’Evento.
              <br />
              <br />
              5. Conseguenze del mancato conferimento dei dati
              <br />
              Il mancato conferimento dei Suoi dati personali preclude la
              prenotazione di un colloquio gratuito con uno specialista presente
              all’Evento. <br />
              <br />
              6. Trasferimento dei dati verso Paesi extra UE
              <br />
              La gestione e la conservazione dei dati avvengono su server
              ubicati in territorio UE. I dati personali non sono oggetto di
              trasferimento al di fuori dell’Unione europea, quindi verso Paesi
              terzi od organizzazioni internazionali ai sensi del GDPR. In ogni
              caso, Lundbeck Italia S.p.A. garantisce sin d’ora che il
              trasferimento dei dati personali extra UE avverrà nel rispetto
              delle disposizioni di legge applicabili (a partire dal Reg. UE n.
              679/2016), stipulando, se necessario, accordi che garantiscano un
              livello di protezione adeguato dei dati personali, e/o adottando
              le clausole contrattuali standard previste dalla Commissione
              europea. Resta ferma la richiesta dell’esplicito consenso al
              trasferimento dei dati per i casi nei quali il trasferimento
              stesso non sia supportato da una decisione di adeguatezza della
              Commissione Europea di cui all’art. 45 del Regolamento UE 2016/679
              o da una delle garanzie adeguate di cui all’art. 46 del
              Regolamento UE 2016/679 già richiamate in parte.
              <br />
              <br />
              7. I Suoi diritti
              <br />
              Fatte salve alcune eccezioni e restrizioni stabilite dalla
              legislazione applicabile, Lei ha diritto di ottenere l'accesso ai
              propri dati personali, nonché la rettifica o la cancellazione
              degli stessi, la limitazione del trattamento, o la portabilità dei
              Suoi dati personali. Lei ha, inoltre, il diritto di opporsi al
              trattamento dei Suoi dati personali.
              <br />
              <br />
              Lei ha il diritto di revocare il consenso in qualsiasi momento,
              senza compromettere la legittimità del trattamento basato sul
              consenso prima della revoca dello stesso. Può revocare il consenso
              inviando una mail a{" "}
              <Link
                href="mailto:Lu-iPrivacy@lundbeck.com"
                target="_blank"
                variant="body2"
              >
                Lu-iPrivacy@lundbeck.com
              </Link>
              .
              <br />
              <br />
              Ha anche il diritto di presentare un reclamo all'autorità di
              controllo competente, che per l’Italia è il Garante per la
              Protezione dei Dati Personali.
              <br />
              <br />
              8. Dettagli di contatto di Lundbeck e del responsabile della
              protezione dei dati (DPO) di Lundbeck
              <br />
              In caso di domande relative alla tutela dei dati personali o se si
              desidera esercitare i propri diritti legali, contattare Lundbeck o
              il responsabile della protezione dei dati di Lundbeck ai seguenti
              contatti:
              <br />
              <br />
              Lundbeck Italia S.p.A.
              <br />
              Via Joe Colombo, 2 - 20124 Milano
              <br />
              Tel 02-677417-1
              <br />
              Email{" "}
              <Link
                href="mailto:Lu-iPrivacy@lundbeck.com"
                target="_blank"
                variant="body2"
              >
                Lu-iPrivacy@lundbeck.com
              </Link>{" "}
              <br />
              <br />
              Responsabile della protezione dei dati di Lundbeck:
              <br />
              E-mail:{" "}
              <Link
                href="mailto:Dataprivacy@Lundbeck.com"
                target="_blank"
                variant="body2"
              >
                Dataprivacy@Lundbeck.com
              </Link>
              <br />
              Il numero di telefono del DPO di Lundbeck può essere consultato su{" "}
              <Link
                href="https://www.Lundbeck.com/"
                target="_blank"
                variant="body2"
              >
                www.Lundbeck.com
              </Link>
              .
              <br />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
