import React, { useState, useEffect } from "react";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
// material
import {
  Grid,
  Stack,
  Typography,
  Box,
  useMediaQuery,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// config
import { urlApi } from "../config";
// components
import SurveyComponent from "../components/depressione/SurveyComponent";
import MessageSuccess from "../components/MessageSuccess";
import MessageError from "../components/MessageError";
import MessageRight from "../components/depressione/MessageRight";
// store
import useStore from "../components/store/useStore";

// ----------------------------------------------------------------------

export default function SurveyDeperessione() {
  const theme = useTheme();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [canProceed, setCanProceed] = useState(true);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const depressioneSurvey = useStore((state) => state.depressioneSurvey);

  const handleCanProceed = async () => {
    setCanProceed(false);
  };

  const inizitalize = async () => {
    try {
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${urlApi}questionnaire/can-proceed`,
        {
          questionnaireid: 2,
        },
        axiosConfig
      );

      const result = response.data;

      if (result.success) {
        setLoading(false);
      }

      // if error
      if (!result.success) {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
      setCanProceed(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    inizitalize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={5} sx={{ pb: 10 }}>
        <MessageRight canProceed={canProceed} />
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          backgroundImage: isMobile ? `` : `url(/images/background.png)`,
          backgroundPosition: "initial",
          backgroundRepeat: "noRepeat",
          backgroundSize: "cover",
          backgroundColor: isMobile ? "#E8F1F3" : "transparent",
        }}
      >
        {loading ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ py: 10 }}
          >
            <TailSpin
              height="40"
              width="40"
              color="#334D99"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </Stack>
        ) : (
          <>
            {canProceed ? (
              <Box sx={{ pb: 10 }}>
                {!depressioneSurvey.level &&
                  !depressioneSurvey.code &&
                  !error && (
                    <>
                      <SurveyComponent setError={setError} />
                      <Button onClick={handleCanProceed}>
                        disabilita accesso
                      </Button>
                    </>
                  )}
                {depressioneSurvey.level && !error && (
                  <MessageSuccess
                    level={depressioneSurvey.level}
                    code={depressioneSurvey.code}
                    type={"depressione"}
                  />
                )}
                {error && <MessageError setError={setError} />}
              </Box>
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ py: 10 }}
                spacing={1}
              >
                <Grid item xs={10} md={8}>
                  <Typography variant="h5">
                    Ops.. tutti gli appuntamenti sono stati già prenotati!
                  </Typography>
                </Grid>
                <Grid item xs={10} md={8}>
                  <Typography variant="body2">
                    Purtroppo tutti gli appuntamenti per il consulto con uno
                    specialista sono già stati prenotati. Ti preghiamo di
                    controllare nuovamente il nostro sito nei prossimi giorni
                    per verificare eventuali disponibilità future.
                    <br />
                    <br />
                    Anche se non dovessi riuscire a prenotare un colloquio, ti
                    invitiamo a partecipare al nostro evento che si terrà presso{" "}
                    <strong>
                      BAM - Biblioteca degli Alberi di Milano, il giorno 11
                      giugno dalle ore 17:00.
                    </strong>{" "}
                    Potrai prendere parte a numerose iniziative come workshop,
                    talk, performance artistiche ed eventualmente usufruire
                    anche di un colloquio con uno specialista se dovessero
                    liberarsi posti all’ultimo minuto.
                  </Typography>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}
