import { Outlet } from "react-router-dom";
//
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

// ----------------------------------------------------------------------

export default function MainLayout({ type }) {
  return (
    <>
      <MainHeader type={type} />
      <div style={{ flexGrow: 1, display: "flex" }}>
        <Outlet />
      </div>
      <MainFooter type={type} />
    </>
  );
}
