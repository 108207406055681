import React from "react";
// material
import { Grid, Stack, Typography, Link, useMediaQuery } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
// routes
import { PATH_PAGE } from "../../../routes/paths";

// ----------------------------------------------------------------------

export default function MainFooter({ type }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container sx={{ backgroundColor: "background.footer", p: 3 }}>
      <Grid item xs={12}>
        <Stack
          direction={isMobile ? "column" : "row"}
          alignItems="center"
          justifyContent="center"
          spacing={isMobile ? 3 : 5}
        >
          <Link
            color="grey.50"
            underline="none"
            variant="caption"
            to={PATH_PAGE.privacy}
            component={RouterLink}
          >
            PRIVACY POLICY
          </Link>

          <Link color="grey.50" underline="none" variant="caption">
            COOKIE
          </Link>

          <Link color="grey.50" underline="none" variant="caption">
            CONDIZIONI DI UTILIZZO
          </Link>
          <Link
            href="mailto:info@cambialatuarelazioneconemicrania.it"
            color="grey.50"
            underline="none"
            variant="caption"
          >
            CONTATTI
          </Link>

          <Stack direction="column" sx={{ pl: 3 }}>
            <Typography variant="caption" color="grey.50">
              Realizzato da
            </Typography>
            <img alt="Edra" src={`/images/edra.png`} height={30} />
          </Stack>

          <Link href="https://www.lundbeck.com/it" target="_blank">
            <img alt="Lundbeck" src={`/images/lundbeck.png`} height={50} />
          </Link>
        </Stack>
      </Grid>
    </Grid>
  );
}
