import React from "react";
// material
import { Grid, Typography } from "@mui/material";

// ----------------------------------------------------------------------

export default function MessageRight() {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={10} md={9}>
        <img alt="emicrania" src={`/images/icon002.png`} height={166} />
      </Grid>
      <Grid item xs={10} md={9}>
        <Typography variant="h5" color="secondary" sx={{ my: 2 }}>
          PRENOTAZIONE COLLOQUIO CON UNO SPECIALISTA
        </Typography>
      </Grid>
      <Grid item xs={10} md={9}>
        <Typography variant="body2">
          Hai la possibilità di prenotare un colloquio con uno specialista in
          data <strong>11 giugno presso la Fondazione Riccardo Catella</strong>,
          all’interno del parco BAM - Biblioteca degli Alberi, in{" "}
          <strong>Via Gaetano de Castillia, 28 - Milano</strong>.<br />
          <br />
          Seleziona subito l’orario che preferisci prima che si esauriscano i
          posti!
        </Typography>
      </Grid>
    </Grid>
  );
}
