import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import arrowForwardRounded from "@iconify/icons-material-symbols/arrow-forward-rounded";
// routes
import { PATH_EMICRANIA, PATH_DEPRESSIONE } from "../../../routes/paths";
// material
import { useTheme } from "@mui/material/styles";
import { Grid, Link, Button, Stack, useMediaQuery } from "@mui/material";

// ----------------------------------------------------------------------

export default function MainHeader({ type }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container>
      <Grid item xs={5} md={6}>
        <img alt="emicrania" src={`/images/logo.png`} height={100} />
      </Grid>
      <Grid item xs={7} md={6}>
        {type && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            height={"100%"}
            sx={{ pr: 3 }}
          >
            {type === "emicrania" ? (
              <Link
                component={RouterLink}
                to={PATH_DEPRESSIONE.survey}
                target="_blank"
              >
                <Button
                  variant="text"
                  size={isMobile ? "small" : ""}
                  color="secondary"
                  // component={RouterLink}
                  // to={PATH_DEPRESSIONE.survey}
                  endIcon={
                    <Icon
                      icon={arrowForwardRounded}
                      style={{ color: theme.palette.secondary.main }}
                    />
                  }
                >
                  <strong>
                    VAI AL QUESTIONARIO
                    <br />
                    SINTOMI DELL'UMORE
                  </strong>
                </Button>
              </Link>
            ) : (
              <Link
                component={RouterLink}
                to={PATH_EMICRANIA.survey}
                target="_blank"
              >
                <Button
                  variant="text"
                  size={isMobile ? "small" : ""}
                  color="secondary"
                  // component={RouterLink}
                  // to={PATH_EMICRANIA.survey}
                  endIcon={
                    <Icon
                      icon={arrowForwardRounded}
                      style={{ color: theme.palette.secondary.main }}
                    />
                  }
                >
                  <strong>
                    VAI AL QUESTIONARIO
                    <br />
                    EMICRANIA
                  </strong>
                </Button>
              </Link>
            )}
          </Stack>
        )}
      </Grid>
    </Grid>
  );
}
