import { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
// material
import { Grid, Typography, Button, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// config
import { urlApi } from "../config";

// ----------------------------------------------------------------------

export default function Delete() {
  const { id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleDelete = async () => {
    try {
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${urlApi}reservation/delete`,
        {
          uniquecode: `${id}`,
        },
        axiosConfig
      );

      const result = response.data;

      if (result.success) {
        setSuccess(result.success);
      }

      // if error
      if (!result.success) {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 404) {
        setError(true);
      }
      return Promise.reject(error.response);
    }
  };

  // useEffect(() => {
  //   if (id) {
  //     inizitalize();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [id]);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          backgroundImage: isMobile ? `` : `url(/images/background.png)`,
          backgroundPosition: "initial",
          backgroundRepeat: "noRepeat",
          backgroundSize: "cover",
          backgroundColor: isMobile ? "#E8F1F3" : "transparent",
          py: 10,
        }}
      >
        {!error ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ py: 10 }}
          >
            {success ? (
              <Typography variant="h6">
                La tua prenotazione è stata cancellata correttamente
              </Typography>
            ) : (
              <Button
                variant="contained"
                onClick={handleDelete}
                style={{ borderRadius: 0 }}
              >
                CONFERMA LA CANCELLAZIONE DELLA PRENOTAZIONE
              </Button>
            )}
          </Stack>
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ py: 10 }}
          >
            <Typography variant="h6">
              Il codice prenotazione {id} non esiste
            </Typography>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
}
