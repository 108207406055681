export const json = {
  // title: "Software developer survey.",
  requiredText: "",
  pages: [
    {
      elements: [
        {
          name: "sex",
          title: "Seleziona il tuo sesso genetico:",
          description: "(campo obbligatorio)",
          isRequired: true,
          type: "radiogroup",
          choices: [
            { value: "m", text: "Maschio" },
            { value: "f", text: "Femmina" },
          ],
        },
      ],
    },
    {
      elements: [
        {
          name: "q1",
          title: "Il mal di testa si presenta:",
          description:
            "(È possibile scegliere più risposte - campo obbligatorio)",
          isRequired: true,
          type: "checkbox",
          choices: [
            {
              value: "1",
              text: "Più frequentemente in sede anteriore (ovvero coinvolge la fronte, gli occhi, le tempie)",
            },
            {
              value: "2",
              text: "Più frequentemente coinvolge solo metà volto/capo",
            },
            {
              value: "3",
              text: "Ha localizzazione variabile, tende a coinvolgere tutta la testa",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          name: "q2",
          title:
            "Quando hai mal di testa devi sempre assumere un anti-dolorifico e/o metterti a riposo/al buio/isolarti altrimenti il dolore diventa insopportabile:",
          description: "(campo obbligatorio)",
          isRequired: true,
          type: "radiogroup",
          choices: [
            { value: "1", text: "Si" },
            { value: "2", text: "No" },
          ],
        },
      ],
    },
    {
      elements: [
        {
          name: "q3",
          title: "Come descriveresti il tuo mal di testa:",
          description:
            "(È possibile scegliere più risposte - campo obbligatorio)",
          isRequired: true,
          type: "checkbox",
          choices: [
            {
              value: "1",
              text: "Come un dolore pulsante, battente, un martello",
            },
            {
              value: "2",
              text: "Come una fitta",
            },
            {
              value: "3",
              text: "Come un dolore che stringe/comprime la testa",
            },
            {
              value: "4",
              text: "Come un peso, un fastidio",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          name: "q4",
          title:
            "Qual è l’intensità del dolore, su una scala da 0 (nessun dolore) a 10 (dolore peggiore della tua vita):",
          description: "(campo obbligatorio)",
          isRequired: true,
          type: "radiogroup",
          choices: [
            {
              value: "1",
              text: "Lieve (dolore variabile fra 0 e 4) – riesci a non assumere farmaci, il dolore tende a passare da solo col tempo",
            },
            {
              value: "2",
              text: "Moderato (dolore variabile fra 5 e 7) – tendi ad assumere analgesici per il controllo del dolore, il mal di testa risponde subito al farmaco, complessivamente un dolore “gestibile”",
            },
            {
              value: "3",
              text: "Severo (dolore variabile fra 8 e 10) – dolore insopportabile che necessita di farmaci e riposo a letto, al buio, con limitazione delle attività e durata anche di più giorni",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          name: "q5",
          title:
            "Quando hai mal di testa si presentano anche i seguenti sintomi:",
          description:
            "(È possibile scegliere più risposte - campo obbligatorio)",
          isRequired: true,
          type: "checkbox",
          choices: [
            {
              value: "1",
              text: "Fastidio per la luce (ovvero preferisci stare al buio, lontano dalle fonti luminose, preferisci indossare occhiali da sole in corso o in seguito all’attacco di mal di testa)",
            },
            {
              value: "2",
              text: "Fastidio per i rumori (ovvero preferisci stare in luoghi silenziosi, lontano da TV o altre fonti di rumore)",
            },
            {
              value: "3",
              text: "Fastidio per gli odori (ovvero alcuni profumi/odori risultano molto fastidiosi quando hai mal di testa o possono addirittura scatenare il mal di testa in alcune occasioni)",
            },
            {
              value: "4",
              text: "Nausea e vomito",
            },
            {
              value: "5",
              text: "Nessuno",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          name: "q6",
          title:
            "Hai notato una maggior frequenza o intensità degli attacchi in corso/nei giorni precedenti o seguenti il flusso mestruale? Hai notato modifiche della cefalea in corso di eventuali terapie ormonali o in gravidanza o in menopausa?",
          description: "(campo obbligatorio)",
          isRequired: true,
          visibleIf: "{sex} = f",
          type: "radiogroup",
          choices: [
            { value: "1", text: "Si" },
            { value: "2", text: "No" },
          ],
        },
      ],
    },
  ],
};
