import { useState } from "react";
import axios from "axios";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/survey.i18n";

import "./index.css";
import { json } from "./json";
// config
import { urlApi } from "../../config";
// store
import useStore from "../../components/store/useStore";

// ----------------------------------------------------------------------

function SurveyComponent({ setError }) {
  const [survey] = useState(new Model(json));
  const formEmicraniaSurvey = useStore((state) => state.formEmicraniaSurvey);

  survey.locale = "it";
  survey.showProgressBar = "bottom";
  survey.showCompletedPage = false;
  survey.showQuestionNumbers = "off";
  survey.onComplete.add((sender, options) => {
    // console.log(JSON.stringify(sender.data, null, 3));
    handleSend(sender.data);
  });

  const handleSend = async (data) => {
    try {
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${urlApi}questionnaire/send`,
        {
          questionnaireid: 1,
          answers: JSON.stringify(data),
        },
        axiosConfig
      );

      const result = response.data;

      if (result.success) {
        formEmicraniaSurvey(result.code, result.level);
      }

      // if error
      if (!result.success) {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
      setError(true);
    }
  };

  return (
    <div>
      {/* {renderExternalNavigation()} */}
      <Survey model={survey} />
    </div>
  );
}

export default SurveyComponent;
