export const json = {
  // title: "Durante le ultime due settimane, per quanti giorni:",
  requiredText: "",
  pages: [
    {
      elements: [
        {
          name: "q1",
          title:
            "Durante le ultime due settimane, ti è capitato di sentirti spesso giù di morale?",
          description: "(campo obbligatorio)",
          isRequired: true,
          type: "radiogroup",
          choices: [
            {
              value: "1",
              text: "Mai",
            },
            {
              value: "2",
              text: "Alcuni giorni",
            },
            {
              value: "3",
              text: "Più della metà dei giorni",
            },
            {
              value: "4",
              text: "Quasi tutti i giorni",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          name: "q2",
          title:
            "Durante le ultime due settimane, ti è capitato di provare poco interesse o piacere per le attività che hai sempre amato fare?",
          description: "(campo obbligatorio)",
          isRequired: true,
          type: "radiogroup",
          choices: [
            {
              value: "1",
              text: "Mai",
            },
            {
              value: "2",
              text: "Alcuni giorni",
            },
            {
              value: "3",
              text: "Più della metà dei giorni",
            },
            {
              value: "4",
              text: "Quasi tutti i giorni",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          name: "q3",
          title:
            "Durante le ultime due settimane, ti è capitato di avere perso o preso peso senza volerlo?",
          description: "(campo obbligatorio)",
          isRequired: true,
          type: "radiogroup",
          choices: [
            {
              value: "1",
              text: "Mai",
            },
            {
              value: "2",
              text: "Alcuni giorni",
            },
            {
              value: "3",
              text: "Più della metà dei giorni",
            },
            {
              value: "4",
              text: "Quasi tutti i giorni",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          name: "q4",
          title:
            "Durante le ultime due settimane, ti è capitato di avere difficoltà a dormire o, al contrario, dormire troppo?",
          description: "(campo obbligatorio)",
          isRequired: true,
          type: "radiogroup",
          choices: [
            {
              value: "1",
              text: "Mai",
            },
            {
              value: "2",
              text: "Alcuni giorni",
            },
            {
              value: "3",
              text: "Più della metà dei giorni",
            },
            {
              value: "4",
              text: "Quasi tutti i giorni",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          name: "q5",
          title:
            "Durante le ultime due settimane, ti è capitato di sentirti agitato o essere lento nel muoverti e/o parlare?",
          description: "(campo obbligatorio)",
          isRequired: true,
          type: "radiogroup",
          choices: [
            {
              value: "1",
              text: "Mai",
            },
            {
              value: "2",
              text: "Alcuni giorni",
            },
            {
              value: "3",
              text: "Più della metà dei giorni",
            },
            {
              value: "4",
              text: "Quasi tutti i giorni",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          name: "q6",
          title:
            "Durante le ultime due settimane, ti è capitato di sentirti affaticato o privo di energie?",
          description: "(campo obbligatorio)",
          isRequired: true,
          type: "radiogroup",
          choices: [
            {
              value: "1",
              text: "Mai",
            },
            {
              value: "2",
              text: "Alcuni giorni",
            },
            {
              value: "3",
              text: "Più della metà dei giorni",
            },
            {
              value: "4",
              text: "Quasi tutti i giorni",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          name: "q7",
          title:
            "Durante le ultime due settimane, ti è capitato di provare sensi di colpa o di inadeguatezza?",
          description: "(campo obbligatorio)",
          isRequired: true,
          type: "radiogroup",
          choices: [
            {
              value: "1",
              text: "Mai",
            },
            {
              value: "2",
              text: "Alcuni giorni",
            },
            {
              value: "3",
              text: "Più della metà dei giorni",
            },
            {
              value: "4",
              text: "Quasi tutti i giorni",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          name: "q8",
          title:
            "Durante le ultime due settimane, ti è capitato di avere difficoltà a concentrarti o a prendere decisioni?",
          description: "(campo obbligatorio)",
          isRequired: true,
          type: "radiogroup",
          choices: [
            {
              value: "1",
              text: "Mai",
            },
            {
              value: "2",
              text: "Alcuni giorni",
            },
            {
              value: "3",
              text: "Più della metà dei giorni",
            },
            {
              value: "4",
              text: "Quasi tutti i giorni",
            },
          ],
        },
      ],
    },
    {
      elements: [
        {
          name: "q9",
          title:
            "Durante le ultime due settimane, ti è capitato di avere pensieri suicidari?",
          description: "(campo obbligatorio)",
          isRequired: true,
          type: "radiogroup",
          choices: [
            {
              value: "1",
              text: "Mai",
            },
            {
              value: "2",
              text: "Alcuni giorni",
            },
            {
              value: "3",
              text: "Più della metà dei giorni",
            },
            {
              value: "4",
              text: "Quasi tutti i giorni",
            },
          ],
        },
      ],
    },
  ],
};
