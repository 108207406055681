import { create } from "zustand";

// ----------------------------------------------------------------------

const initialState = {
  emicraniaSurvey: {
    code: "",
    level: "",
  },
  depressioneSurvey: {
    code: "",
    level: "",
  },
  reservedSlot: {
    slot: "",
    email: "",
  },
};

const useStore = create((set) => ({
  ...initialState,
  formEmicraniaSurvey: async (code, level) =>
    set((state) => ({
      ...state,
      emicraniaSurvey: {
        ...state.emicraniaSurvey,
        code,
        level,
      },
    })),
  //
  formDepressioneSurvey: async (code, level) =>
    set((state) => ({
      ...state,
      depressioneSurvey: {
        ...state.depressioneSurvey,
        code,
        level,
      },
    })),
  //
  formReserved: async (slot, email) =>
    set((state) => ({
      ...state,
      reservedSlot: {
        ...state.reservedSlot,
        slot,
        email,
      },
    })),
  //
}));

export default useStore;
