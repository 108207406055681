import { useState, useEffect } from "react";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import checkLg from "@iconify/icons-bi/check-lg";
import alertCircleOutline from "@iconify/icons-mdi/alert-circle-outline";
// material
import {
  Grid,
  Stack,
  Typography,
  Divider,
  Button,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// config
import { urlApi } from "../config";
// components
import ReservationForm from "../components/reservation/ReservationForm";
import MessageRight from "../components/reservation/MessageRight";
// store
import useStore from "../components/store/useStore";

// ----------------------------------------------------------------------

export default function Reservation() {
  const { id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const reservedSlot = useStore((state) => state.reservedSlot);
  const [slots, setSlots] = useState([]);
  const [countSlots, setCountSlots] = useState(0);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [userData, setUserData] = useState();

  const inizitalize = async () => {
    try {
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${urlApi}reservation/slots`,
        {
          uniquecode: `${id}`,
        },
        axiosConfig
      );

      const result = response.data;

      if (result.success) {
        setSlots(result.slots);
        if (result.name && result.lastname && result.email) {
          setUserData({
            name: result.name,
            surname: result.lastname,
            email: result.email,
          });
        }
        setLoading(false);
      }

      // if error
      if (!result.success) {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
      setError(true);
      setErrorMessage(error.response.data.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id && !success) {
      inizitalize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (slots.length > 0) {
      let countAvailable = 0;
      slots.map((slot) => {
        countAvailable = countAvailable + slot.available;
        return null;
      });
      setCountSlots(countAvailable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slots]);

  const resendMail = async () => {
    setResendDisabled(true);

    try {
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios.post(
        `${urlApi}reservation/resendmail`,
        {
          uniquecode: id,
        },
        axiosConfig
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (resendDisabled) {
      setTimeout(() => {
        setResendDisabled(false);
      }, 20000);
    }
  }, [resendDisabled]);

  return (
    <Grid container>
      <Grid item xs={12} md={5} sx={{ pb: 10 }}>
        <MessageRight />
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          backgroundImage: isMobile ? `` : `url(/images/background.png)`,
          backgroundPosition: "initial",
          backgroundRepeat: "noRepeat",
          backgroundSize: "cover",
          backgroundColor: isMobile ? "#E8F1F3" : "transparent",
          pb: 10,
        }}
      >
        {loading ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ py: 10 }}
          >
            <TailSpin
              height="40"
              width="40"
              color="#334D99"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </Stack>
        ) : (
          <>
            {!error ? (
              <>
                {!success ? (
                  <>
                    <ReservationForm
                      slots={slots}
                      countSlots={countSlots}
                      setCountSlots={setCountSlots}
                      setSuccess={setSuccess}
                      userData={userData}
                    />
                  </>
                ) : (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ mb: 10, pt: 10 }}
                    spacing={3}
                  >
                    <Grid item xs={10} md={8}>
                      <Icon
                        icon={checkLg}
                        height={55}
                        width={55}
                        style={{ color: theme.palette.error.main }}
                      />
                    </Grid>
                    {countSlots > 0 ? (
                      <>
                        <Grid item xs={10} md={8}>
                          <Typography variant="h5">
                            La tua prenotazione è stata confermata!
                          </Typography>
                        </Grid>
                        <Grid item xs={10} md={8}>
                          <Typography variant="body2">
                            La tua prenotazione per il giorno 11 giugno nella
                            fascia oraria{" "}
                            {moment(reservedSlot.slot, "HHmmss").format(
                              "HH:mm"
                            )}{" "}
                            è stata confermata. Il Codice Identificativo è:{" "}
                            <strong>{id}</strong>
                            <br />
                            <br />
                            Abbiamo inviato una email di conferma all’indirizzo
                            di posta {reservedSlot.email}. Nella mail trovi il
                            tuo Codice ID e il questionario da te compilato.{" "}
                            <strong>
                              Ricordati di portarlo con te in sede di colloquio
                            </strong>
                            .
                          </Typography>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={10} md={8}>
                          <Typography variant="h5">
                            Ora sei in lista d’attesa!
                          </Typography>
                        </Grid>
                        <Grid item xs={10} md={8}>
                          <Typography variant="body2">
                            Abbiamo inviato una email di conferma all’indirizzo
                            di posta {reservedSlot.email}. Nella mail trovi il
                            tuo Codice ID e il questionario da te compilato.
                            <br />
                            <br />
                            Ti aggiorneremo quando nuovi posti saranno
                            disponibili per i colloqui con uno specialista.
                          </Typography>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={10} md={8}>
                      <Divider
                        sx={{ backgroundColor: theme.palette.primary.main }}
                      />
                    </Grid>
                    <Grid item xs={10} md={8}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={1}
                      >
                        <Icon icon={alertCircleOutline} />
                        <Typography variant="body2">
                          Non hai ricevuto la email?
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={10} md={8}>
                      <Button
                        variant="contained"
                        color="info"
                        style={{
                          borderRadius: 0,
                          background: "#41748D",
                          border: "1px solid #40738C",
                          boxShadow: "none",
                          flex: "flex: 1 1 0px",
                          width: "50%",
                          padding: "10px",
                        }}
                        disabled={resendDisabled}
                        onClick={resendMail}
                        // component={RouterLink}
                        // to={`/reservation/${code}`}
                      >
                        REINVIA CONFERMA PRENOTAZIONE
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </>
            ) : (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ py: 10 }}
              >
                <Typography variant="subtitle2" color="error">
                  {errorMessage === "Invalid code"
                    ? "Questo codice prenotazione non esiste"
                    : errorMessage === "Already reserved"
                    ? "Codice prenotazione già utilizzato"
                    : "Qualcosa è andato storto"}
                </Typography>
              </Stack>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}
