import React from "react";
// material
import { Stack, Typography, Button } from "@mui/material";

// ----------------------------------------------------------------------

export default function MessageError({ setError }) {
  const handleReset = () => {
    setError(false);
  };

  return (
    <Stack direction="column" alignItems="center" spacing={3}>
      <Typography variant="h4" color="error" textAlign="center">
        Errore!
        <br />
        <Typography component="span" variant="subtitle2" color="black">
          qualcosa è andato storto
        </Typography>
      </Typography>
      <Button variant="contained" color="inherit" onClick={handleReset}>
        Riprova
      </Button>
    </Stack>
  );
}
