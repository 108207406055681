import React from "react";
import { Box, Typography } from "@mui/material";

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "primary",
      }}
    >
      <Typography variant="h1">404</Typography>
      <Typography variant="h6">La pagina richiesta non esiste</Typography>
    </Box>
  );
}
