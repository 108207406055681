import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};

const PRIMARY = {
  lighter: "#5370c6",
  light: "#4060bf",
  main: "#334D99",
  dark: "#023e8a",
  darker: "#03045e",
  contrastText: "#fff",
};
const SECONDARY = {
  lighter: "#da5c82",
  light: "#d64772",
  main: "#BF2B57",
  dark: "#952244",
  darker: "#811d3b",
  contrastText: "#fff",
};
const INFO = {
  lighter: "#FFFFFF",
  light: "#FFFFFF",
  main: "#FFFFFF",
  dark: "#e5e5e5",
  darker: "#d8d8d8",
  contrastText: "#334D99",
};
const SELECT = {
  lighter: "#FFFFFF",
  light: "#FFFFFF",
  main: "#41748D",
  dark: "#e5e5e5",
  darker: "#d8d8d8",
  contrastText: "#FFFFFF",
};
const SUCCESS = {
  lighter: "#6ee7b7",
  light: "#34d399",
  main: "#10b981",
  dark: "#059669",
  darker: "#047857",
  contrastText: "#fff",
};
const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
  contrastText: GREY[800],
};
const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff",
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
  select: createGradient(SELECT.light, SELECT.main),
};

const COMMON = {
  common: { black: "#000", white: "#fff" },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  select: { ...SELECT },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    text: { primary: "#484848", secondary: GREY[600], disabled: GREY[500] },
    background: {
      paper: "#fff",
      default: "#fff",
      neutral: GREY[200],
      footer: "#41748D",
    },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    text: { primary: "#fff", secondary: GREY[500], disabled: GREY[600] },
    background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action },
  },
};

export default palette;
